export const MODALS_IDS = {
  TRIM_AUDIO_MODAL: 'trim-audio-modal',
  TRIM_VIDEO_MODAL: 'trim-video-modal',
  CONFIRM_DELETE_MODAL: 'confirm-delete-modal',
  COMPLETE_SIGNUP_MODAL: 'complete-signup-modal',
  CONFIRMATION_MODAL: 'confirmation-modal',
  CONFIRM_REMOVE_VIDEO_BACKGROUND_MODAL: 'confirm-remove-video-background-modal',
  ERROR_MODAL: 'error-modal',
  CROPPER_MODAL: 'cropper-modal',
  POSTER_ITEMS_LIST_MODAL: 'POSTER_ITEMS_LIST_MODAL',
  MASKING_MODAL: 'masking-modal',
  MESSAGE_MODAL: 'message-modal',
  SAVE_CONFLICT_MODAL: 'save-conflict-modal',
  AUDIO_TYPE_MODAL: 'audio-type-modal',
  USER_MENU_MODAL: 'user-menu-modal',
  USER_MEDIA_MODAL: 'user-media-modal',
  STOCK_MODAL: 'stock-media-modal',
  RESUMABLE_STOCK_MODAL: 'resumable-stock-media-modal',
  SHARE_COLLECTION_MODAL: 'share-collection-modal',
  USER_TABLE_MODAL: 'user-table-modal',
  USER_SCHEDULE_MODAL: 'user-schedule-modal',
  MORE_OPTIONS_MODAL: 'more-options-modal',
  COLLECTION_ITEM_MORE_OPTIONS_MODAL: 'collection-item-more-options-modal',
  AUDIO_ITEM_RENAME_MODAL: 'audio-item-rename-modal',
  REPLACE_MEDIA_MODAL: 'replace-media-modal',
  ROYALTY_FILTERS_MODAL: 'royalty-filters-modal',
  POSTER_IN_GENERATION_MODAL: 'poster-in-generation-modal',
  RESIZE_MODAL: 'resize-modal',
  COLOR_BACKGROUND_MODAL: 'color-background-modal',
  UPLOAD_FONT_AGREEMENT_MODAL: 'upload-font-agreement-modal',
  CHANGE_PLAN_MODAL: 'change-plan-modal',
  FANCYTEXT_MODAL: 'fancytext-modal',
  EVENT_FILTERS_MODAL: 'event-filters-modal',
  EVENT_AREA_FILTERS_MODAL: 'event-area-filters-modal',
  CREATE_EVENT_MODAL: 'create-event-modal',
  EDIT_EVENT_MODAL: 'edit-event-modal',
  PMW_STOCK_UPLOAD_FAILED_MODAL: 'pmw-stock-upload-failed-modal',
  SOCIAL_POSTS_DIALOG_MODAL: 'social-posts-dialog-modal',
  EMAIL_METRICS_DOWNLOAD_PDF_MODAL: 'email-metrics-download-pdf-modal',
  SOCIAL_MEDIA_WIZARD_MOBILE_FOOTER_MODAL: 'social-media-wizard-mobile-footer-modal',
  SOCIAL_ACCOUNTS_CONNECTION_MODAL: 'social-accounts-conntection-modal',
  POSTER_EDITOR_ADD_ITEM_MODAL: 'poster-editor-add-item-modal',
  POSTER_EDITOR_GRID_MODAL: 'poster-editor-grid-modal',
  POSTER_EDITOR_FOLDS_MODAL: 'poster-editor-folds-modal',
  POSTER_EDITOR_TITLE_MODAL: 'poster-editor-title-modal',
  POSTER_EDITOR_INTRO_ANIMATION_MODAL: 'poster-editor-intro-animation-modal',
  POSTER_EDITOR_ITEM_POSITION_MODAL: 'poster-editor-item-position-modal',
  POSTER_EDITOR_ITEM_OPACITY_MODAL: 'poster-editor-item-opacity-modal',
  POSTER_EDITOR_ITEM_FONT_SIZE_MODAL: 'poster-editor-item-font-size-modal',
  POSTER_EDITOR_ITEM_FONT_STYLE_MODAL: 'poster-editor-item-font-style-modal',
  POSTER_EDITOR_ITEM_SPELLCHECK_MODAL: 'poster-editor-item-spellcheck-modal',
  POSTER_EDITOR_ITEM_HYPERLINK_MODAL: 'poster-editor-item-hyperlink-modal',
  POSTER_EDITOR_ITEM_ALIGNMENT_MODAL: 'poster-editor-item-alignment-modal',
  POSTER_EDITOR_ITEM_SHADOW_MODAL: 'poster-editor-item-shadow-modal',
  POSTER_EDITOR_ITEM_FLIP_MODAL: 'poster-editor-item-flip-modal',
  POSTER_EDITOR_ITEM_STRETCH_MODAL: 'poster-editor-item-stretch-modal',
  POSTER_EDITOR_ITEM_ADJUSTMENTS_MODAL: 'poster-editor-item-adjustments-modal',
  POSTER_EDITOR_LAYOUT_FILL_MODAL: 'poster-editor-layout-fill-modal',
  POSTER_EDITOR_TEXT_FILL_MODAL: 'poster-editor-text-fill-modal',
  POSTER_EDITOR_ITEM_FILTERS_MODAL: 'poster-editor-item-filters-modal',
  POSTER_EDITOR_ITEM_BACKGROUND_MODAL: 'poster-editor-item-background-modal',
  POSTER_EDITOR_ITEM_EFFECTS_MODAL: 'poster-editor-item-effects-modal',
  POSTER_EDITOR_TEXT_OUTLINE_MODAL: 'poster-editor-text-outline-modal',
  POSTER_EDITOR_QR_CONTENT_MODAL: 'poster-editor-qr-content-modal',
  POSTER_EDITOR_QR_CONTENT_COLOR_MODAL: 'poster-editor-qr-content-color-modal',
  POSTER_EDITOR_QR_CONTENT_BACKGROUND_MODAL: 'poster-editor-qr-content-background-modal',
  POSTER_EDITOR_VECTOR_BORDER_MODAL: 'poster-editor-vector-border-modal',
  POSTER_EDITOR_VECTOR_FILL_MODAL: 'poster-editor-vector-fill-modal',
  POSTER_EDITOR_FANCY_TEXT_MODAL: 'poster-editor-fancy-text-modal',
  POSTER_EDITOR_FANCY_TEXT_EFFECT_MODAL: 'poster-editor-fancy-text-effect-modal',
  POSTER_EDITOR_FANCY_TEXT_FONT_MODAL: 'poster-editor-fancy-text-font-modal',
  POSTER_EDITOR_DRAW_BRUSH_MODAL: 'poster-editor-draw-brush-modal',
  POSTER_EDITOR_DRAW_BRUSH_SIZE_MODAL: 'poster-editor-draw-brush-size-modal',
  POSTER_EDITOR_DRAW_BRUSH_COLOR_MODAL: 'poster-editor-draw-brush-color-modal',
  POSTER_EDITOR_DRAWING_COLOR_MODAL: 'poster-editor-drawing-color-modal',
  POSTER_EDITOR_MORE_OPTIONS_MODAL: 'poster-editor-more-options-modal',
  POSTER_EDITOR_NUMBER_OF_TABS_MODAL: 'poster-editor-number-of-tabs-modal',
  POSTER_EDITOR_TABS_TEXT_MODAL: 'poster-editor-tabs-text-modal',
  POSTER_EDITOR_ITEM_LAYOUT_MODAL: 'poster-editor-item-layout-modal',
  POSTER_EDITOR_ITEM_DATE_STYLES_MODAL: 'poster-editor-item-date-styles-modal',
  POSTER_EDITOR_ITEM_FONT_STYLE_2_MODAL: 'poster-editor-item-font-style-2-modal',
  POSTER_EDITOR_LAYOUT_ITEM_BORDER_MODAL: 'poster-editor-layout-item-border-modal',
  POSTER_EDITOR_LAYOUT_ITEM_SPACING_MODAL: 'poster-editor-layout-item-spacing-modal',
  POSTER_EDITOR_MENU_ITEM_ICON_STYLES_MODAL: 'poster-editor-menu-item-icon-styles-modal',
  POSTER_EDITOR_SLIDESHOW_APPLY_PROPERTIES_MODAL: 'poster-editor-slideshow-apply-properties-modal',
  POSTER_EDITOR_SLIDESHOW_SLIDES_MODAL: 'poster-editor-slideshow-slides-modal',
  POSTER_EDITOR_NAV_MORE_OPTIONS_MODAL: 'poster-editor-nav-more-options-modal',
  POSTER_EDITOR_LANGUAGE_MODAL: 'poster-editor-language-modal',
  POSTER_EDITOR_TEXT_ITEM_FONT_MODAL: 'poster-editor-text-item-font-modal',
  POSTER_EDITOR_BACKGROUND_TYPE_MODAL: 'poster-editor-background-type-modal',
  REPLACE_BACKGROUND_IMAGE_MODAL: 'replace-background-image-modal',
  MOVE_MULTIMEDIA_MODAL: 'move-multimedia-modal',
  ADD_ITEMS_MODAL: 'add-items-modal',
  SOCIAL_MEDIA_RESIZE_OPTIONS: 'social-media-resize-options',
  MY_STUFF_MOBILE_NAVIGATION_MODAL: 'mystuff-mobile-nav-modal',
  GOOGLE_MAP_SELECTOR_MODAL: 'google-map-selector-modal',
  EMBED_VIDEOS_EMAILMAKER_MODAL: 'embed-videos-emailmaker-modal',
  POSTER_EDITOR_BLEED_MODAL: 'poster-editor-bleed-modal',
  POSTER_EDITOR_LAYOUT_ITEM_FONT_MODAL: 'poster-editor-layout-item-font-modal',
  POSTER_EDITOR_SLIDESHOW_TRANSITION_MODAL: 'poster-editor-slideshow-transition-modal',
  POSTER_EDITOR_FANCY_TEXT_SHAPE_MODAL: 'poster-editor-fancy-text-shape-modal',
  POSTER_EDITOR_TAB_ITEM_SEPARATOR_MODAL: 'poster-editor-tab-item-separator-modal',
  POSTER_EDITOR_FANCY_TEXT_COLORS_MODAL: 'poster-editor-fancy-text-colors-modal',
  POSTER_EDITOR_ITEM_LIST_MODAL: 'poster-editor-item-list-modal',
  DURATION_DROPDOWN_MODAL: 'duration-dropdown-modal',
  EDIT_AUDIO_ITEM_MODAL: 'edit-audio-item-modal',
  POSTER_EDITOR_EXPORT_MODAL: 'poster-editor-export-modal',
  POSTER_EDITOR_ADD_SLIDESHOW_MODAL: 'poster-editor-add-slideshow-modal',
  ADD_FANCYTEXT_COLOR_MODAL: 'add-fancytext-color-modal',
  AUTO_CAPTIONS_MODAL: 'auto-captions-modal',
  POSTER_EDITOR_EDIT_TEXT_MODAL: 'poster-editor-edit-text-modal',
  MY_STUFF_MOBILE_FOLDER_OPTIONS: 'mystuff-mobile-folder-options-modal',
  EMBEDDED_EDITOR_CONSENT_MODAL: 'embeddededitor-consent-modal',
  POSTER_EDITOR_GUIDES_MODAL: 'poster-editor-guides-modal',
  POSTER_EDITOR_ITEM_LETTER_SPACING_MODAL: 'poster-editor-item-letter-spacing-modal',
  POSTER_EDITOR_ITEM_LINE_HEIGHT_MODAL: 'poster-editor-item-line-height-modal',
  POSTER_EDITOR_REPLACE_AUDIO_MODAL: 'poster-editor-replace-audio-modal',
  RECORD_IMAGE_AND_VIDEO_MODAL: 'record-image-and-video-modal',
  RECORD_AUDIO_MODAL: 'record-audio-modal',
  CONNECT_SSO_ACCOUNT_MODAL: 'connect-sso-account-modal',
  SOCIAL_POST_SELECT_DESIGN_MODAL: 'social-post-select-design-modal',
  SOCIAL_MEDIA_SELECT_COVER_MODAL: 'social-media-select-cover-modal',
  MEDIA_TYPE_MODAL: 'media-type-modal',
  AUDIENCE_EDIT_INFO_MODAL: 'audience-edit-info-modal',
  SETTINGS_MODAL: 'settings-modal-react',
  PREMIUM_AGREEMENT_MODAL: 'premium-license-agreement-modal',
  AUTHENTICATE_MODAL: 'auth-modal-react',
  TWO_FACTOR_AUTHENTICATION_VERIFICATION_MODAL: 'two-factor-authentication-verification-modal',
  PASSWORD_VERIFICATION_MODAL: 'password-verification-modal',
  MANAGE_TWO_FACTOR_AUTHENTICATION_MODAL: 'manage-two-factor-authentication-modal',
  BRAND_ACTIVITY_MODAL: 'brand-activity-modal',
  BRAND_COLOR_MODAL: 'brand-color-modal',
  EDIT_POSTER_SUBTITLES_MODAL: 'edit-poster-subtitles-modal',
  EDIT_POSTER_SUBTITLE_MORE_OPTIONS_MODAL: 'edit-poster-subtitle-more-options-modal',
  AI_TEXT_PROMPT_BOX_MODAL: 'ai-text-prompt-box-modal',
  TIMELINE_MODAL: 'timeline-modal',
  PUBLISHING_OPTIONS_MODAL: 'publishing-options-modal',
  ACCOUNT_DELETION_MODAL: 'account-deletion-modal',
  POSTER_SUBTITLE_TEMPLATE_STYLES_MODAL: 'poster-subtitle-template-styles-modal',
  EMAIL_CAMPAIGN_DIALOG_MODAL: 'email-campaign-dialog-modal',
};
